import React from 'react';

import clsx from 'clsx';

type ColorsType = 'dark-blue' | 'white';

export interface ILabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
  children: React.ReactNode;
  color?: ColorsType;
  htmlFor: string;
  required?: boolean;
}

const BASE_CLASSES: string = 'block text-[15px] mb-1';

const COLORS: Record<ColorsType, string> = {
  'dark-blue': 'text-blue-800',
  white: 'text-white',
};

export const Label: React.FC<ILabelProps> = ({
  className,
  children,
  color = 'dark-blue',
  htmlFor,
  required = false,
  ...rest
}) => {
  const labelClasses = clsx(
    BASE_CLASSES,
    {
      [COLORS[color]]: color,
    },
    className,
  );

  return (
    // eslint-disable-next-line
    <label className={labelClasses} htmlFor={htmlFor} {...rest}>
      {children} {required ? ' *' : null}
    </label>
  );
};

export default Label;
