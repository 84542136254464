import React from 'react';

export interface IHeaderTitleProps {
  children: string | React.ReactNode;
}

export const HeaderTitle: React.FC<IHeaderTitleProps> = ({ children }) => {
  return <h1 className="text-base text-blue-800 uppercase">{children}</h1>;
};

export default HeaderTitle;
