import React from 'react';

import { nanoid } from 'nanoid';

export interface IFormErrorsProps {
  error?: string | [{ message: string; code: string }];
}

export const FormErrors: React.FC<IFormErrorsProps> = ({ error }) => {
  return (
    <>
      {Array.isArray(error) &&
        error.map((item) => (
          <p key={nanoid()} className="text-sm text-red-700">
            {item.message ?? item}
          </p>
        ))}
      {(typeof error === 'string' || error instanceof String) && (
        <span className="text-sm text-red-700">{error}</span>
      )}
    </>
  );
};

export default FormErrors;
