import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { endOfDay } from 'date-fns';
import { DateRangePicker, Range } from 'react-date-range';

import useOnClickOutside from '@/hooks/useOnClickOutside';
import useToggle from '@/hooks/useToggle';
import { getDatePickerLabel } from '@/utils/helpers';
import { defaultStaticRanges } from '@/utils/static-ranges';

export interface IDateRangePickerFilterProps {
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (item: any) => void;
  value: Range[] | undefined;
}

export const DateRangePickerFilter: React.FC<IDateRangePickerFilterProps> = ({
  isClearable = false,
  isDisabled = false,
  onChange,
  value,
}) => {
  const { value: showDatePicker, setFalse, toggle } = useToggle(false);
  const [datePickerLabel, setDatePickerLabel] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    setDatePickerLabel(getDatePickerLabel(value));
  }, [value]);

  useOnClickOutside(ref, () => {
    setFalse();
  });

  return (
    <div ref={ref}>
      <div
        className={clsx(
          'py-1.5 cursor-default border bg-white flex justify-between items-center pl-0 pr-2',
          {
            'bg-white': !isDisabled,
            'bg-[#F2F2F2] border-[#F7F7F7] text-[#9CA3AF]': isDisabled,
            'text-[#9CA3AF]': datePickerLabel === 'Select...',
          },
        )}
        style={{ borderColor: '#dadada' }}
        onClick={() => {
          if (!isDisabled) {
            toggle();
          }
        }}
      >
        <span className="pl-2 pr-2 truncate">{datePickerLabel}</span>
        <div className="flex">
          {isClearable && datePickerLabel !== 'Select...' && (
            <svg
              aria-hidden="true"
              className="mr-1"
              fill="#2c3c54"
              focusable="false"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                onChange({
                  selection: {
                    startDate: null,
                    endDate: null,
                    key: 'selection',
                  },
                });
              }}
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          )}
          <svg
            aria-hidden="true"
            fill="#2c3c54"
            focusable="false"
            height="20"
            viewBox="0 0 20 20"
            width="20"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </div>
      </div>
      {!isDisabled && showDatePicker && (
        <div className="absolute z-10 mt-2 border shadow-lg">
          <DateRangePicker
            direction="horizontal"
            inputRanges={[]}
            maxDate={endOfDay(new Date())}
            minDate={new Date(+0)}
            months={1}
            moveRangeOnFirstSelection={false}
            rangeColors={['#003366']}
            ranges={value}
            staticRanges={defaultStaticRanges}
            onChange={(item) => {
              const itemCopy = { ...item };
              if (itemCopy.selection?.endDate) {
                itemCopy.selection.endDate = endOfDay(
                  itemCopy.selection.endDate,
                );
              }
              onChange(itemCopy);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePickerFilter;
