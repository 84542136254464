import { useState } from 'react';

import {
  GlobalStateContext,
  IGlobalState,
} from '@/contexts/GlobalStateContext';

interface IGlobalStateProviderProps {
  children: React.ReactNode;
  value: Partial<IGlobalState>;
}

const GlobalStateProvider: React.FC<IGlobalStateProviderProps> = ({
  children,
  value,
}) => {
  const [globalState, setGlobalState] = useState(value);
  return (
    <GlobalStateContext.Provider
      value={{
        // @ts-ignore
        globalState,
        setGlobalState,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateProvider };
