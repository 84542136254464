import React, { useRef } from 'react';

import { Input } from '@/components/atoms';

import CrossIcon from '../../../svgs/icons/cross.svg';
import SearchIcon from '../../../svgs/icons/search.svg';

export interface ISearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  setFilters: (prevState: any) => void;
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  id,
  setFilters,
  ...rest
}) => {
  const ref = useRef<any>(null);

  return (
    <div className="relative text-blue-900">
      <Input
        className="w-full py-1.5 pl-3 border pr-10 focus:shadow-outline focus:outline-none"
        id={id}
        innerRef={ref}
        {...rest}
      />
      <button className="absolute right-0 mr-1 top-[8px] w-7 focus:outline-none">
        {rest.value ? (
          <div className="w-4 mt-[2px]">
            <CrossIcon
              className="cursor-pointer"
              onClick={() => {
                setFilters((prevState: any) => ({
                  ...prevState,
                  search: undefined,
                }));
                ref.current.value = '';
              }}
            />
          </div>
        ) : (
          <SearchIcon className="w-5" />
        )}
      </button>
    </div>
  );
};

export default SearchInput;
