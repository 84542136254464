import React from 'react';

export interface IContentTitleProps {
  children: React.ReactNode;
  subtitle?: string;
}

export const ContentTitle: React.FC<IContentTitleProps> = ({
  children,
  subtitle,
}) => {
  return (
    <div className="mb-3">
      <h2 className="text-[17px] font-bold text-blue-800 uppercase">
        {children}
      </h2>
      {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
    </div>
  );
};

export default ContentTitle;
