import React from 'react';

import Link from 'next/link';
import { EqualHeightElement } from 'react-equal-height/clean';
import { NumericFormat } from 'react-number-format';

import { Status } from '@/components/atoms';
import { StoreItemListType } from '@/types/StoreTypes';

import UserIcon from '../../../svgs/icons/user.svg';

export interface IStoreCardProps {
  store: StoreItemListType;
}

export const StoreCard: React.FC<IStoreCardProps> = ({ store }) => {
  return (
    <Link href={`/partners-management/stores/${store.api_identifier}/overview`}>
      <div className="bg-white shadow cursor-pointer">
        <div className="flex items-center px-4 py-3 text-white bg-blue-800">
          <p className="uppercase truncate">{store.name}</p>
        </div>
        <div className="px-4 py-3 text-blue-800">
          <EqualHeightElement name="store-card-content">
            <div className="flex items-center justify-between mb-1">
              <div className="pr-3">
                <p className="text-base">{store.partner}</p>
                <p className="text-sm italic text-gray-400">
                  ID: {store.terminal_id}
                </p>
              </div>
              <div>
                <Status type={store.status} />
              </div>
            </div>
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center">{store.country.name}</div>
              <div className="flex items-center">
                <UserIcon className="w-4" />
                <p className="ml-2 text-sm">
                  <NumericFormat
                    thousandSeparator
                    displayType="text"
                    suffix=" Users"
                    value={store.user_count}
                  />
                </p>
              </div>
            </div>
          </EqualHeightElement>
        </div>
      </div>
    </Link>
  );
};

export default StoreCard;
