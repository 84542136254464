import React from 'react';

import clsx from 'clsx';

import { FormErrors } from '@/components/atoms/FormErrors';

export interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  fullWidth?: boolean;
  id: string;
  error?:
    | string
    | [
        {
          message: string;
          code: string;
        },
      ]
    | undefined;
}

const BASE_CLASSES: string =
  'focus:shadow-outline focus:outline-none border px-2 py-2';

export const TextArea: React.FC<ITextAreaProps> = ({
  className,
  fullWidth = false,
  id,
  error,
  ...rest
}) => {
  const textareaClasses = clsx(className, BASE_CLASSES, {
    'border-gray-300': !error,
    'border-red-700': error,
    'w-full': fullWidth,
  });

  return (
    <div>
      <div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <textarea className={textareaClasses} id={id} {...rest} />
      </div>
      <FormErrors error={error} />
    </div>
  );
};

export default TextArea;
