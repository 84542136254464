import React from 'react';

export interface IHrProps {
  color?: string;
  height?: number;
  margin?: number;
}

export const Hr: React.FC<IHrProps> = ({
  color = '#DADADA',
  height = 1,
  margin = 8,
}) => {
  return (
    <hr
      className="border-0"
      style={{
        backgroundColor: color,
        height,
        marginTop: margin,
        marginBottom: margin,
      }}
    />
  );
};

export default Hr;
