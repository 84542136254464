import React from 'react';

import TableExpandMinusIcon from '../../../svgs/icons/table-expand-minus.svg';
import TableExpandPlusIcon from '../../../svgs/icons/table-expand-plus.svg';

export interface ITableExpandIconProps {
  expanded?: boolean;
  onExpand?: any;
  record?: any;
}

export const TableExpandIcon: React.FC<ITableExpandIconProps> = ({
  expanded,
  onExpand,
  record,
}) => {
  return (
    <div
      className="flex justify-center"
      onClick={(e) => {
        onExpand(record, e);
      }}
    >
      {!expanded && <TableExpandPlusIcon />}
      {expanded && <TableExpandMinusIcon />}
    </div>
  );
};

export default TableExpandIcon;
