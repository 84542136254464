import React from 'react';

import { nanoid } from 'nanoid';

export interface IBreadcrumbsProps {
  breadcrumbs: string[];
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    breadcrumbs && (
      <p>
        {breadcrumbs.map((item, index, array) => (
          <span key={nanoid()} className="text-sm text-gray-500">
            {item}
            {index !== array.length - 1 ? ' > ' : ''}
          </span>
        ))}
      </p>
    )
  );
};

export default Breadcrumbs;
