import React from 'react';

import simpleSvgPlaceholder from '@cloudfour/simple-svg-placeholder';
import { format } from 'date-fns';
import Link from 'next/link';
import { EqualHeightElement } from 'react-equal-height/clean';
import { NumericFormat } from 'react-number-format';

import { Hr, Status } from '@/components/atoms';
import { useGlobalState } from '@/contexts/GlobalStateContext';
import { PartnerItemListType } from '@/types/PartnerTypes';

import StoreIcon from '../../../svgs/icons/store.svg';
import UserIcon from '../../../svgs/icons/user.svg';

export interface IPartnerCardProps {
  partner: PartnerItemListType;
  url: string;
}

export const PartnerCard: React.FC<IPartnerCardProps> = ({ partner, url }) => {
  const { globalState } = useGlobalState();

  return (
    <Link href={url}>
      <div className="h-full bg-white shadow cursor-pointer">
        <EqualHeightElement name="partner-card-header">
          <div className="w-10">
            <div
              className="w-10 h-10 bg-cover rounded-full"
              style={{
                backgroundImage: `url(${simpleSvgPlaceholder({
                  width: 44,
                  height: 44,
                  text: partner.company_name.charAt(0).toUpperCase(),
                  fontSize: 18,
                  fontWeight: 'light',
                })})`,
              }}
            />
          </div>
          <div className="flex-1 w-full">
            <p className="w-full pl-3 uppercase line-clamp-2">
              {partner.company_name}
            </p>
            <p className="pl-3 text-sm italic text-gray-300">
              {partner.registration_country.name}
            </p>
          </div>
        </EqualHeightElement>
        <div className="p-4 text-blue-800 bg-white">
          <div className="flex">
            <div className="w-2/3">
              {partner.issuance_enabled &&
                partner.payment_type === 'in_advance' && (
                  <>
                    <p className="text-sm">Balance</p>
                    <p className="text-xl">
                      <NumericFormat
                        thousandSeparator
                        displayType="text"
                        value={partner.balance}
                      />
                      <span>
                        &nbsp;
                        {globalState.currencyOwner.loyalty_currency_type_name}
                      </span>
                    </p>
                    <p className="text-xs italic text-gray-400">
                      <NumericFormat
                        thousandSeparator
                        displayType="text"
                        value={partner.fiat_balance}
                      />
                      <span>&nbsp;{partner.fiat_currency}</span>
                    </p>
                  </>
                )}
              {partner.issuance_enabled &&
                partner.payment_type === 'on_credit' && (
                  <>
                    <p className="text-sm">Credit remaining</p>
                    <p className="text-xl">
                      <NumericFormat
                        thousandSeparator
                        displayType="text"
                        value={partner.credit}
                      />
                      <span>
                        &nbsp;
                        {globalState.currencyOwner.loyalty_currency_type_name}
                      </span>
                    </p>
                    <p className="text-xs italic text-gray-400">
                      <NumericFormat
                        thousandSeparator
                        displayType="text"
                        value={partner.fiat_credit}
                      />
                      <span>&nbsp;{partner.fiat_currency}</span>
                    </p>
                  </>
                )}
              {(!partner.issuance_enabled || !partner.payment_type) && (
                <div className="h-16">&nbsp;</div>
              )}
            </div>
            <div className="flex-1">
              <Status type={partner.status} />
            </div>
          </div>
          <Hr />
          <div className="flex justify-between mb-2">
            <div className="flex items-center">
              <StoreIcon className="w-5" />
              <p className="ml-2 text-sm">
                <NumericFormat
                  thousandSeparator
                  displayType="text"
                  suffix=" Stores"
                  value={partner.store_count}
                />
              </p>
            </div>
            <div className="flex items-center">
              <UserIcon className="w-4" />
              <p className="ml-2 text-sm">
                <NumericFormat
                  thousandSeparator
                  displayType="text"
                  suffix=" User"
                  value={partner.user_count}
                />
              </p>
            </div>
          </div>
          <p className="text-xs text-gray-400">
            Partnership since {format(new Date(partner.created_at), 'MMM yyyy')}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default PartnerCard;
