import React from 'react';

import clsx from 'clsx';
import { nanoid } from 'nanoid';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

export type ItemType = {
  href: string;
  title: string;
  showIf?: boolean;
};

export interface ITabNavigationProps {
  items: ItemType[];
}

export const TabNavigation: React.FC<ITabNavigationProps> = ({ items }) => {
  const router = useRouter();

  return (
    <div className="sticky top-0 z-30 shadow-[0_2px_5px_0_rgb(0,0,0,18%)]">
      <div className="flex px-6 overflow-x-auto bg-white md:px-8 lg:px-14">
        {items.map(
          (item: ItemType) =>
            (item.showIf || typeof item.showIf === 'undefined') && (
              <NextLink
                key={nanoid()}
                className={clsx(
                  'text-[15px] flex-shrink-0 px-4 py-3 mr-3 text-blue-900',
                  {
                    'font-bold border-b-2 border-green-500':
                      router.asPath.includes(item.href),
                  },
                )}
                href={item.href}
              >
                {item.title}
              </NextLink>
            ),
        )}
      </div>
    </div>
  );
};

export default TabNavigation;
