/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import clsx from 'clsx';
import NextLink from 'next/link';

type ColorsType = 'blue' | 'green' | 'white';
type SizeType = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl';

export interface ILinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children: React.ReactNode;
  color?: ColorsType;
  size?: SizeType;
  underlined?: boolean;
  to: string | { href: string; as: string };
}

const COLORS: Record<ColorsType, string> = {
  blue: 'text-blue-900',
  green: 'text-green-500',
  white: 'text-white',
};

const SIZES: Record<SizeType, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
};

export const Link: React.FC<ILinkProps> = ({
  children,
  className,
  color = 'green',
  size = 'base',
  to,
  underlined = true,
  ...rest
}: ILinkProps) => {
  const linkClasses = clsx(className, {
    [COLORS[color]]: color,
    [SIZES[size]]: size,
    underline: underlined,
    'hover:no-underline': underlined,
    'hover:underline': !underlined,
  });

  if (typeof to === 'string') {
    return (
      <NextLink className={linkClasses} href={to} {...rest}>
        {children}
      </NextLink>
    );
  }
  return (
    <NextLink as={to.as} className={linkClasses} href={to.href} {...rest}>
      {children}
    </NextLink>
  );
};

export default Link;
