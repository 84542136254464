import React from 'react';

import clsx from 'clsx';
import DatePicker from 'react-datepicker';

import { FormErrors } from '@/components/atoms/FormErrors';

import CalendarIcon from '../../../svgs/icons/calendar.svg';

export interface IInputDateProps {
  dateFormat?: string;
  error?:
    | string
    | [
        {
          message: string;
          code: string;
        },
      ]
    | undefined;
  onChange: (date: Date) => void;
  value: Date | null | undefined;
}

export const InputDate: React.FC<IInputDateProps> = ({
  error,
  dateFormat,
  onChange,
  value,
}) => {
  return (
    <div>
      <div
        className={clsx('flex items-center border bg-white px-2 py-[5.25px]', {
          'border-gray-300': !error,
          'border-red-700': error,
        })}
      >
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          className="focus:shadow-outline focus:outline-none text-[15px]"
          closeOnScroll={true}
          dateFormat={dateFormat}
          dropdownMode="select"
          selected={value}
          onChange={onChange}
        />
        <CalendarIcon className="w-5 h-5 text-blue-850" />
      </div>
      <FormErrors error={error} />
    </div>
  );
};

export default InputDate;
