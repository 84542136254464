import React from 'react';

import clsx from 'clsx';
import Link from 'next/link';

import { Spinner } from '..';

type ColorType = 'blue' | 'green' | 'red';
type SizeType = 'xs' | 'sm' | 'md' | 'lg';
type VariantType = 'clear' | 'outline' | 'solid';

type VariantStyleType = {
  base: string;
  colors: Record<ColorType, string>;
};
type StylesType = Record<VariantType, VariantStyleType>;

interface IButtonTypeProps {
  ariaLabel?: string;
  as?: 'button';
  children: React.ReactNode;
  className?: string;
  color?: ColorType;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  href?: never;
  onClick: (event: React.MouseEvent) => void;
  size?: SizeType;
  target?: never;
  variant?: VariantType;
}

interface ILinkTypeProps {
  ariaLabel?: never;
  as?: 'link';
  children: React.ReactNode;
  className?: string;
  color?: ColorType;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  href: string;
  onClick?: never;
  size?: SizeType;
  target?: string;
  variant?: VariantType;
}

export type IButtonProps = IButtonTypeProps | ILinkTypeProps;

const BASE_CLASSES: string =
  'focus:shadow-outline focus:outline-none relative inline-flex items-center justify-center overflow-hidden leading-normal text-center align-middle';

const SIZES: Record<SizeType, string> = {
  xs: 'text-xs px-2 py-1',
  sm: 'text-sm px-6 py-2',
  md: 'text-base px-8 py-1.5',
  lg: 'lg:text-lg px-10 lg:py-3 text-base py-2',
};

const STYLES: StylesType = {
  solid: {
    base: 'shadow rounded-full',
    colors: {
      blue: 'bg-blue-900 text-white hover:bg-blue-800',
      green: 'bg-green-500 text-white hover:bg-green-900',
      red: 'bg-red-500 text-white hover:bg-red-900',
    },
  },
  outline: {
    base: 'font-normal border rounded-full',
    colors: {
      blue: 'border-blue-800 text-blue-800 hover:border-blue-900 hover:text-blue-900 bg-white',
      green:
        'border-green-500 text-green-500 hover:border-[#1C2E49] hover:text-[#1C2E49] bg-white',
      red: 'border-red-500 text-red-500 hover:border-[#1C2E49] hover:text-[#1C2E49] bg-white',
    },
  },
  clear: {
    base: '',
    colors: {
      blue: 'text-blue-700 hover:underline',
      green: 'text-green-500 hover:underline',
      red: 'text-red-500 hover:underline',
    },
  },
};

const LOADING_STYLES = {
  solid: {
    colors: {
      blue: 'white',
      green: 'white',
      red: 'white',
    },
  },
  outline: {
    colors: {
      blue: '#003366',
      green: '#00B49C',
      red: '#00B49C',
    },
  },
  clear: {
    colors: {
      blue: '#003366',
      green: '#00B49C',
      red: '#00B49C',
    },
  },
};

export const Button: React.FC<IButtonProps> = ({
  as = 'button',
  children,
  className,
  color = 'blue',
  disabled = false,
  fullWidth = false,
  loading = false,
  href = '',
  onClick = undefined,
  size = 'md',
  target = undefined,
  variant = 'solid',
}: IButtonProps) => {
  const btnClasses = clsx(
    BASE_CLASSES,
    {
      [SIZES[size]]: size,
      [STYLES[variant].base]: variant,
      [STYLES[variant].colors[color]]: variant,
      'w-full': fullWidth,
      'opacity-60': disabled,
      'opacity-60 cursor-not-allowed pointer-events-none': disabled || loading,
    },
    className,
  );

  if (as === 'link') {
    return (
      <Link className={btnClasses} href={href} target={target}>
        {loading ? (
          <Spinner color={LOADING_STYLES[variant].colors[color]} />
        ) : (
          children
        )}
      </Link>
    );
  }
  return (
    <button
      className={btnClasses}
      disabled={disabled || loading}
      type="button"
      onClick={onClick}
    >
      {loading ? (
        <Spinner color={LOADING_STYLES[variant].colors[color]} />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
