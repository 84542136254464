import React, { FC } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import CrossIcon from '../../../svgs/icons/cross.svg';
import TickIcon from '../../../svgs/icons/tick.svg';

type Types = 'success' | 'error' | 'warning';

interface NotificationProps {
  description?: string;
  duration?: number;
  id: string;
  title: string;
  type?: Types;
}

export type NotificationRenderFunction = FC<
  NotificationProps & { onClose: () => void }
>;

export interface NotifyOptions extends NotificationProps {
  render?: NotificationRenderFunction;
}

export interface INotificationProps extends NotifyOptions {
  onClose: () => void;
}

const BASE_CLASSES: string =
  'flex w-full px-4 py-5 mb-1 items-center space-x-6 justify-center cursor-pointer shadow';

const TYPES: Record<Types, string> = {
  success: 'bg-green-500 text-white',
  error: 'bg-red-400 text-white',
  warning: 'bg-yellow-500 text-white',
};

const ICONS: Record<Types, React.ReactNode> = {
  success: <TickIcon className="w-5 h-5" />,
  error: <CrossIcon className="w-4 h-4" />,
  warning: null,
};

export const Notification: React.FC<INotificationProps> = ({
  render: Render,
  id,
  type = 'success',
  ...props
}) => {
  const notificationClasses = clsx(BASE_CLASSES, {
    [TYPES[type]]: type,
  });

  return (
    <motion.div
      key={id}
      layout
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 0 }}
      initial={{ opacity: 0, x: 0 }}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 40,
      }}
    >
      {Render ? (
        <Render id={id} {...props} />
      ) : (
        <div className={notificationClasses} onClick={props.onClose}>
          {ICONS[type] && <div>{ICONS[type]}</div>}
          <div>
            <h3 className="text-base font-medium">{props.title}</h3>
            {props.description && (
              <p className="text-sm">{props.description}</p>
            )}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Notification;
