import {
  CurrencyOwnerType,
  CurrencyOwnerUserType,
} from '@/types/CurrencyOwnerTypes';
import { axiosFrontend } from '@/utils/axios';

export const fetchUserInformation = async () => {
  return axiosFrontend.get<CurrencyOwnerType>(
    '/api/currency-owners/user-information',
  );
};

export const fetchCurrencyOwnersUsers = async () => {
  return axiosFrontend.get<CurrencyOwnerUserType[]>(
    '/api/currency-owners/users',
  );
};
