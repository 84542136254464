/* eslint-disable global-require */
module.exports = {
  mode: 'jit',
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '2rem',
        sm: '2rem',
        lg: '4rem',
        xl: '5rem',
        '2xl': '6rem',
      },
    },
    fontFamily: {
      sans: ['Lato', 'sans-serif'],
      serif: ['Abhaya Libre', 'serif'],
    },
    extend: {
      colors: {
        green: {
          500: '#00B49C',
        },
        gray: {
          200: '#F7F7F7',
          500: '#9B9B9B',
        },
        blue: {
          800: '#4C7093',
          850: '#003366',
          900: '#2C3C54',
        },
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms')({
      strategy: 'class',
    }),
  ],
};
