import { createContext, useContext, useState, useCallback } from 'react';

import { uniqBy } from 'lodash';

import { INotificationProps, NotifyOptions } from '@/components/atoms';

interface NotificationContextI {
  notify: (options: NotifyOptions) => void;
}

export const NotificationContext = createContext<NotificationContextI>(
  {} as NotificationContextI,
);

export function useNotify() {
  return useContext(NotificationContext);
}

export function useNotifications() {
  const [notifications, setNotifications] = useState<INotificationProps[]>([]);

  const notify = useCallback((options: NotifyOptions): void => {
    function removeNotification() {
      setNotifications((prev) => prev.filter((n) => n.id !== options.id));
    }

    const newNotification = {
      ...options,
      onClose: removeNotification,
    };

    setNotifications((prev) => uniqBy([newNotification, ...prev], 'id'));

    setTimeout(removeNotification, options.duration || 6000);
  }, []);

  return { notify, notifications };
}
