import React from 'react';

import ReactSelect, { GroupBase, StylesConfig, Theme } from 'react-select';

import { FormErrors } from '@/components/atoms/FormErrors';

export interface ISelectProps
  extends Omit<
    React.ComponentProps<typeof ReactSelect>,
    'styles' | 'theme' | 'ref' | 'className'
  > {
  className?: string;
  color?: string;
  error?:
    | string
    | [
        {
          message: string;
          code: string;
        },
      ]
    | undefined;
  innerRef?: any;
}

export const Select: React.FC<ISelectProps> = ({
  className = '',
  color = null,
  error,
  innerRef,
  ...rest
}) => {
  const theme = (themeProps: Theme) => ({
    ...themeProps,
    borderRadius: 0,
  });
  const customStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    option: (provided, { isSelected, isFocused }) => {
      return {
        ...provided,
        backgroundColor: isSelected || isFocused ? '#EFF2F7' : 'white',
        color: '#000',
        fontWeight: isSelected ? 'bold' : 'normal',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#EFF2F7',
        },
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      height: '100%',
      boxShadow: 'none',
      borderWidth: 1,
      borderColor: error ? '#b91c1c' : '#d1d5db',
      '&:hover': {
        borderColor: error ? '#b91c1c' : '#d1d5db',
      },
      '&:active': {
        borderColor: error ? '#b91c1c' : '#d1d5db',
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
      paddingLeft: 5,
      position: 'relative',
      top: 1,
      svg: {
        fill: '#2C3C54',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      paddingLeft: 5,
      paddingRight: 10,
      svg: {
        fill: '#2C3C54',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0.4rem',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: color ?? '#2C3C54',
    }),
    placeholder: (provided) => ({
      ...provided,
      top: 15,
      color: '#9CA3AF',
    }),
  };

  return (
    <div className={className}>
      <ReactSelect
        ref={innerRef}
        styles={customStyles}
        theme={theme}
        {...rest}
      />
      <FormErrors error={error} />
    </div>
  );
};

export default Select;
