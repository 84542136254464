/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import cookie from 'cookie';
import { format } from 'date-fns';
import { camelCase, upperFirst } from 'lodash';
import { NextApiRequest, NextApiResponse } from 'next';
import { Range } from 'react-date-range';
// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig';

import {
  partnerBillingPeriodOptions,
  partnerPaymentTypeOptions,
  partnersStatusesOptions,
  storeStatusesOptions,
  transactionsTypesOptions,
  userRolesOptions,
  userStatusesOptions,
} from './constants';
import { defaultStaticRanges } from './static-ranges';
// @ts-ignore
import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const getBreakpointValue = (value: string): number =>
  // @ts-ignore
  +fullConfig.theme.screens[value].slice(
    0,
    // @ts-ignore
    fullConfig.theme.screens[value].indexOf('px'),
  );

export const getDatePickerLabel = (value: Range[] | undefined): string => {
  // @ts-ignore
  if (value[0].startDate && value[0].endDate) {
    // @ts-ignore
    const formattedStartDate = format(value[0].startDate, 'dd-MM-yyyy');
    // @ts-ignore
    const formattedEndDate = format(value[0].endDate, 'dd-MM-yyyy');
    let label =
      formattedStartDate === formattedEndDate
        ? formattedStartDate
        : `${formattedStartDate} - ${formattedEndDate}`;
    // @ts-ignore
    defaultStaticRanges.forEach((staticRange) => {
      if (
        format(staticRange.range().startDate, 'dd-MM-yyyy') ===
          formattedStartDate &&
        format(staticRange.range().endDate, 'dd-MM-yyyy') === formattedEndDate
      ) {
        label = staticRange.label;
      }
    });
    return label;
  }
  return 'Select...';
};

export const getJWTKeyName = () => {
  const ENVIRONMENT =
    process.env.SENTRY_ENVIRONMENT ||
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

  return `jwtToken${upperFirst(camelCase(ENVIRONMENT))}`;
};

export function parseCookies(req: NextApiRequest) {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie);
}

export const getHeaders = (
  _req: NextApiRequest,
  cookies: Record<string, string>,
  hasAuthorization = true,
) => {
  const headers: Record<string, string> = {
    'X-Authorization-Key': `${process.env.UPT_API_AUTHORIZATION_KEY}`,
  };

  if (hasAuthorization) {
    headers.Authorization = `JWT ${cookies[getJWTKeyName()]}`;
  }

  return headers;
};

export const getCatchResponse = (res: NextApiResponse, error: AxiosError) => {
  if (
    error.response?.status === 400 ||
    error.response?.status === 403 ||
    error.response?.status === 404
  ) {
    return res.status(error.response?.status).json(error.response.data);
  }
  if (error.response?.status === 401) {
    return res.status(401).json({ message: 'Error! Try again later.' });
  }
  Sentry.captureException(error);
  return res.status(400).json({ message: 'Error! Try again later.' });
};

export const getOriginOrDestionationAccountForMember = (
  type: string,
  subType: string,
) => {
  return (type === 'member_exchange' && subType === 'out') ||
    (type === 'redemption' && subType === 'standard') ||
    type === 'gift_card_exchange' ||
    type === 'loyalty_currency_expiration' ||
    type === 'reversal'
    ? 'origin_account'
    : 'destination_account';
};

export const getOriginOrDestionationAccountForPartner = (
  type: string,
  subType: string,
) => {
  if (type === 'redemption' && subType === 'standard') {
    return 'facilitator';
  }
  if (
    (type === 'member_exchange' && subType === 'out') ||
    type === 'gift_card_exchange' ||
    type === 'loyalty_currency_expiration' ||
    type === 'reversal'
  ) {
    return 'destination_account';
  }
  return 'origin_account';
};

export const getTypeIssuanceOrRedemption = (
  type: string,
  subType: string | null,
) => {
  return (type === 'member_exchange' && subType === 'out') ||
    (type === 'redemption' && subType === 'standard') ||
    type === 'gift_card_exchange' ||
    type === 'loyalty_currency_expiration' ||
    type === 'reversal'
    ? 'redemption'
    : 'issuance';
};

export const getTypeLabel = (
  typeValue: string,
  subTypeValue: string | null,
) => {
  const found = transactionsTypesOptions.find((type) => {
    if (type.value.includes('__')) {
      const typeSep = type.value.split('__');
      return typeSep[0] === typeValue && typeSep[1] === subTypeValue;
    }
    return type.value === typeValue;
  });
  if (found) return found.label;
  return '';
};

export const slugify = (str: string) => {
  return str
    .normalize('NFKD')
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .trim()
    .replace(/[-\s]+/g, '-');
};

export const getDomainWithoutSubdomain = (url: string) => {
  const urlParts = url.split('.');
  return urlParts.slice(0).slice(-2).join('.').split(':')[0];
};

// See https://stackoverflow.com/a/39308026
export const parseUrl = (url: string) => {
  const m = url.match(
    /^((?:([^:/?#]+:)(?:\/\/))?((?:([^/?#:]*):([^/?#:]*)@)?([^/?#:]*)(?::([^/?#:]*))?))?([^?#]*)(\?[^#]*)?(#.*)?$/,
  );
  const r = {
    hash: m?.[10] || '', // #asd
    host: m?.[3] || '', // localhost:257
    hostname: m?.[6] || '', // localhost
    href: m?.[0] || '', // http://username:password@localhost:257/deploy/?asd=asd#asd
    origin: m?.[1] || '', // http://username:password@localhost:257
    pathname: m?.[8] || (m?.[1] ? '/' : ''), // /deploy/
    port: m?.[7] || '', // 257
    protocol: m?.[2] || '', // http:
    search: m?.[9] || '', // ?asd=asd
    username: m?.[4] || '', // username
    password: m?.[5] || '', // password
  };
  if (r.protocol.length === 2) {
    r.protocol = `file:///${r.protocol.toUpperCase()}`;
    r.origin = `${r.protocol}//${r.host}`;
  }
  r.href = r.origin + r.pathname + r.search + r.hash;
  return r;
};

export const downloadCSV = (data: string) => {
  const blob = new Blob([data], {
    type: 'text/csv;charset=utf-8;',
  });
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(blob, 'export.csv');
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'export.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const partnerAuditLogFieldsValues = (field: string, value: string) => {
  if (field === 'status') {
    return partnersStatusesOptions.find((item) => item.value === value)?.label;
  }
  if (field === 'payment_type') {
    return partnerPaymentTypeOptions.find((item) => item.value === value)
      ?.label;
  }
  if (field === 'billing_period') {
    return partnerBillingPeriodOptions.find((item) => item.value === value)
      ?.label;
  }
  return value;
};

export const storeAuditLogFieldsValues = (field: string, value: string) => {
  if (field === 'status') {
    return storeStatusesOptions.find((item) => item.value === value)?.label;
  }
  return value;
};

export const userAuditLogFieldsValues = (field: string, value: string) => {
  if (field === 'role') {
    return userRolesOptions.find((item) => item.value === value)?.label;
  }
  if (field === 'status') {
    return userStatusesOptions.find((item) => item.value === value)?.label;
  }
  return value;
};
