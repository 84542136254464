import React from 'react';

import clsx from 'clsx';
import { isBoolean } from 'lodash';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { FormErrors } from '@/components/atoms/FormErrors';

export interface IInputNumericFormatProps extends NumericFormatProps {
  className?: string;
  error?:
    | boolean
    | string
    | [
        {
          message: string;
          code: string;
        },
      ]
    | undefined;
}

const BASE_CLASSES: string =
  'focus:shadow-outline focus:outline-none border px-2 py-[0.43rem] text-right text-[15px] placeholder:text-left';

export const InputNumberFormat: React.FC<IInputNumericFormatProps> = ({
  className,
  error,
  value,
  ...rest
}) => {
  const inputClasses = clsx(
    BASE_CLASSES,
    {
      'border-gray-300': !error,
      'border-red-700': error,
    },
    className,
  );

  return (
    <div>
      <NumericFormat
        thousandSeparator
        allowNegative={
          typeof rest.allowNegative === 'undefined' ? false : rest.allowNegative
        }
        className={inputClasses}
        id={rest.id}
        name={rest.name}
        placeholder={rest.placeholder || 'Type here'}
        value={parseFloat(
          `${value?.toString().replace(/(\.0*|(?<=(\..*))0*)$/, '')}`,
        )}
        {...rest}
      />
      {!isBoolean(error) && <FormErrors error={error} />}
    </div>
  );
};

export default InputNumberFormat;
