// Needed some customization: https://github.com/abhijithvijayan/react-minimal-side-navigation
import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import Link from 'next/link';

type NavItemProps = {
  title: string;
  itemId: string;
  elemBefore?: React.FC<unknown>;
  subNav?: NavItemProps[];
  showIf?: boolean;
};

export interface ISidebarNavigationProps {
  items: NavItemProps[];
  activeItemId: string;
}

interface IParentItemProps {
  isActiveTab: boolean;
  ElemBefore: React.FunctionComponent | undefined;
  item: NavItemProps;
}

const ParentItem: React.FC<IParentItemProps> = ({
  isActiveTab,
  ElemBefore = null,
  item,
}) => {
  return item.showIf || typeof item.showIf === 'undefined' ? (
    <span
      className={clsx(
        'flex items-center px-4 py-3.5 text-base font-normal cursor-pointer hover:bg-blue-800',
        { 'bg-blue-800': isActiveTab },
      )}
    >
      {/** Prefix Icon Component */}
      {ElemBefore && <ElemBefore />}

      <span className="ml-3">{item.title}</span>
    </span>
  ) : null;
};

export const SidebarNavigation: React.FC<ISidebarNavigationProps> = ({
  activeItemId,
  items,
}) => {
  const [activeSubNav, setActiveSubNav] = useState({
    expanded: true,
    selectedId: activeItemId,
  });

  // Listen for parent prop changes and update state
  useEffect(() => {
    setActiveSubNav((originalSubNav) => ({
      expanded: originalSubNav.expanded,
      selectedId: activeItemId,
    }));
  }, [activeItemId]);

  function handleSubNavExpand(item: NavItemProps): void {
    if (activeSubNav.expanded) {
      const currentItemOrSubNavItemIsOpen: boolean =
        // either the parent item is expanded already
        item.itemId === activeSubNav.selectedId ||
        // or one of its expandable children is selected
        (item.subNav &&
          item.subNav.some(
            (_subNavItem) => _subNavItem.itemId === activeSubNav.selectedId,
          )) ||
        false;

      setActiveSubNav({
        expanded:
          item.subNav && item.subNav.length > 0
            ? !currentItemOrSubNavItemIsOpen
            : false, // disable expansion value, if not expandable
        selectedId: item.itemId,
      });
    } else {
      setActiveSubNav({
        expanded: !!(item.subNav && item.subNav.length > 0), // expand if expandable
        selectedId: item.itemId,
      });
    }
  }

  return (
    <>
      {items.length > 0 && (
        <nav role="navigation">
          <ul className="border-t border-blue-800">
            {items.map((item: NavItemProps) => {
              const ElemBefore = item.elemBefore;
              const isItemSelected: boolean =
                item.itemId === activeSubNav.selectedId ||
                activeSubNav.selectedId.startsWith(item.itemId);
              const isActiveTab: boolean =
                // item is expanded and
                activeSubNav.expanded &&
                // either the current expandable section is selected
                (isItemSelected ||
                  // or some item in the expandable section of the current item is selected or active
                  (item.subNav &&
                    item.subNav.some(
                      (_subNavItem: NavItemProps) =>
                        _subNavItem.itemId === activeSubNav.selectedId,
                    )) ||
                  false);

              return (
                <li key={item.itemId} className="border-b border-blue-800">
                  {!item.subNav && (
                    <Link href={item.itemId}>
                      <ParentItem
                        ElemBefore={ElemBefore}
                        isActiveTab={isActiveTab}
                        item={item}
                      />
                    </Link>
                  )}

                  {item.subNav && item.subNav.length > 0 && (
                    <div
                      onClick={(): void => {
                        handleSubNavExpand(item);
                      }}
                    >
                      <ParentItem
                        ElemBefore={ElemBefore}
                        isActiveTab={isActiveTab}
                        item={item}
                      />
                    </div>
                  )}

                  {item.subNav && item.subNav.length > 0 && isActiveTab && (
                    <ul>
                      {item.subNav.map((subNavItem: NavItemProps) => {
                        return item.showIf ||
                          typeof item.showIf === 'undefined' ? (
                          <li
                            key={subNavItem.itemId}
                            className="sidebar-subitem"
                          >
                            <Link
                              className="sidebar-line"
                              href={subNavItem.itemId}
                              onClick={(): void => {
                                setActiveSubNav({
                                  ...activeSubNav,
                                  selectedId: subNavItem.itemId,
                                });
                              }}
                            >
                              <span
                                className={clsx(
                                  'flex items-center w-full py-2 pr-4 text-base font-normal transition duration-75 cursor-pointer pl-8 hover:bg-white/10 group',
                                  {
                                    'bg-white/10':
                                      activeSubNav.selectedId ===
                                        subNavItem.itemId ||
                                      activeSubNav.selectedId.startsWith(
                                        subNavItem.itemId,
                                      ),
                                  },
                                )}
                              >
                                <span
                                  className={clsx(
                                    'inline-block w-[10px] h-[10px] mr-2 rounded-full group-hover:bg-white z-[5]',
                                    {
                                      'bg-white':
                                        activeSubNav.selectedId ===
                                          subNavItem.itemId ||
                                        activeSubNav.selectedId.startsWith(
                                          subNavItem.itemId,
                                        ),
                                      'bg-blue-800':
                                        !activeSubNav.selectedId.startsWith(
                                          subNavItem.itemId,
                                        ),
                                    },
                                  )}
                                />
                                <span className="ml-3">{subNavItem.title}</span>
                              </span>
                            </Link>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </>
  );
};

export default SidebarNavigation;
