import React from 'react';

import clsx from 'clsx';
import startCase from 'lodash/startCase';

export type TypesType =
  | 'active'
  | 'used'
  | 'enabled'
  | 'pending'
  | 'disabled'
  | 'blocked'
  | 'expired'
  | 'inactive';

export interface IStatusProps {
  className?: string;
  type: TypesType;
}

const BASE_CLASSES: string = 'text-center border-2 px-6 py-1 text-[15px]';

const TYPES: Record<TypesType, string> = {
  active: 'border-[#7ED321] text-[#7ED321]',
  used: 'border-[#7ED321] text-[#7ED321]',
  enabled: 'border-[#7ED321] text-[#7ED321]',
  pending: 'border-orange-300 text-orange-300',
  disabled: 'border-gray-400 text-gray-400',
  inactive: 'border-gray-400 text-gray-400',
  expired: 'border-gray-400 text-gray-400',
  blocked: 'border-gray-400 text-gray-400',
};

export const Status: React.FC<IStatusProps> = ({ className, type }) => {
  const statusClasses = clsx(BASE_CLASSES, TYPES[type], className);
  return <div className={statusClasses}>{startCase(type)}</div>;
};

export default Status;
