// https://dev.to/bionicjulia/creating-an-accordion-component-in-react-with-typescript-and-tailwindcss-1edo
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import ChevronIcon from '../../../svgs/icons/chevron.svg';

export interface IAccordionProps {
  afterTitle?: React.ReactNode | ((active: boolean) => React.ReactNode);
  children: React.ReactNode;
  openedByDefault?: boolean;
  title: string | React.ReactNode;
}

export const Accordion: React.FC<IAccordionProps> = ({
  afterTitle,
  children,
  openedByDefault = false,
  title,
}: IAccordionProps) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const [rotate, setRotate] = useState('transform duration-500 ease');

  const contentSpace = useRef(null);

  function toggleAccordion() {
    setActive(active === false);
    // @ts-ignore
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`);
    setRotate(
      active
        ? 'transform duration-500 ease'
        : 'transform duration-500 ease rotate-180',
    );
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      if (openedByDefault && contentSpace.current.scrollHeight) {
        // @ts-ignore
        setHeight(`${contentSpace.current.scrollHeight}px`);
        setRotate('rotate-180');
        setActive(true);
      } else {
        setFirstLoad(false);
      }
    }, 100);
  }, [contentSpace, openedByDefault]);

  useEffect(() => {
    if (firstLoad && openedByDefault) {
      setTimeout(() => {
        setFirstLoad(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div>
      <div className="flex items-center">
        <div className="flex-1" role="button" onClick={toggleAccordion}>
          <div className="box-border inline-flex items-center outline-none appearance-none cursor-pointer focus:outline-none">
            {typeof title === 'string' ? (
              <p
                dangerouslySetInnerHTML={{ __html: title }}
                // eslint-disable-next-line react/no-danger
                className="pr-3 text-[17px] font-bold text-left text-blue-800 uppercase"
              />
            ) : (
              <div className="pr-3 text-[17px] font-bold text-left text-blue-800 uppercase">
                {title}
              </div>
            )}
            <div className={`${rotate}`}>
              <ChevronIcon className="w-4 text-blue-800" />
            </div>
          </div>
        </div>
        {afterTitle && (
          <div className="flex-none">
            {typeof afterTitle === 'function' && afterTitle(active)}
            {React.isValidElement(afterTitle) && afterTitle}
          </div>
        )}
      </div>
      <div
        ref={contentSpace}
        className={clsx('overflow-hidden', {
          'ease-in-out transition-max-height duration-500': !firstLoad,
        })}
        style={{ maxHeight: `${height}` }}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
