import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { CurrencyOwnerType } from '@/types/CurrencyOwnerTypes';

export interface IGlobalState {
  currencyOwner: CurrencyOwnerType;
  routerTracker: string[];
}

export const GlobalStateContext = createContext({
  globalState: {} as IGlobalState,
  setGlobalState: {} as Dispatch<SetStateAction<Partial<IGlobalState>>>,
});

export function useGlobalState() {
  const context = useContext(GlobalStateContext);

  if (!context)
    throw new Error(
      'useGlobalState must be used inside a `GlobalStateProvider`',
    );

  return context;
}
