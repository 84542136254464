import React, { useEffect, useState } from 'react';

import Error from 'next/error';
import { useCookies } from 'react-cookie';

import { useGlobalState } from '@/contexts/GlobalStateContext';
import { fetchUserInformation } from '@/services/currency-owners/currency-owner';
import { getJWTKeyName } from '@/utils/helpers';

export interface IAuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<IAuthWrapperProps> = ({
  children,
}: IAuthWrapperProps) => {
  const [loading, setLoading] = useState(true);
  const { globalState, setGlobalState } = useGlobalState();
  const [cookies] = useCookies([getJWTKeyName()]);

  useEffect(() => {
    async function fetch() {
      if (!(getJWTKeyName() in cookies)) {
        window.location.href = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/#/auth/login`;
      }

      const res = await fetchUserInformation();
      setGlobalState((prevState) => ({
        ...prevState,
        currencyOwner: res.data,
      }));
      setLoading(false);
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return null;

  if (!loading && !globalState.currencyOwner) {
    return <Error statusCode={404} withDarkMode={false} />;
  }

  return <>{children}</>;
};

export { AuthWrapper };
