import { ReactNode } from 'react';

import { AnimatePresence } from 'framer-motion';

import { Notification } from '@/components/atoms';
import {
  NotificationContext,
  useNotifications,
} from '@/contexts/NotificationContext';

interface NotificationProviderProps {
  children: ReactNode;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const { notify, notifications } = useNotifications();

  return (
    <NotificationContext.Provider value={{ notify }}>
      <div className="fixed top-0 z-50 w-full">
        <AnimatePresence>
          {notifications.map((n) => (
            <Notification key={n.id} {...n} />
          ))}
        </AnimatePresence>
      </div>
      {children}
    </NotificationContext.Provider>
  );
}
