import React from 'react';

export interface IStickyBarProps {
  children: React.ReactNode;
}

export const StickyBar: React.FC<IStickyBarProps> = ({ children }) => {
  return (
    <div
      className="fixed bottom-0 right-0 z-20 w-full p-4 text-center bg-white shadow-[0_-1px_13px_-2px_rgba(0,0,0,0.7)]"
      style={{ width: 'calc(100% - 256px)' }}
    >
      {children}
    </div>
  );
};

export default StickyBar;
