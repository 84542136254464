/* eslint-disable react/jsx-props-no-spreading */
import '@/styles/main.css';

import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import CookieConsent from 'react-cookie-consent';

import { Link } from '@/components/atoms';
import { AuthWrapper } from '@/layouts/AuthWrapper';
import { GlobalStateProvider } from '@/providers/GlobalStateProvider';
import { NotificationProvider } from '@/providers/NotificationProvider';
import { Config } from '@/utils/Config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <GlobalStateProvider
          value={{ currencyOwner: undefined, routerTracker: [] }}
        >
          <Head>
            <meta key="charset" charSet="UTF-8" />
            <meta content="#2C3C54" name="theme-color" />
            <meta
              key="viewport"
              content="width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=5.0"
              name="viewport"
            />
          </Head>
          <DefaultSeo
            dangerouslySetAllPagesToNoFollow
            dangerouslySetAllPagesToNoIndex
            defaultTitle={`${Config.site_name}`}
            titleTemplate={`%s | ${Config.site_name}`}
          />
          <AuthWrapper>
            <Component {...pageProps} />
          </AuthWrapper>
          <div id="modal" />
          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools initialIsOpen />
          )}
          <CookieConsent
            buttonStyle={{
              color: '#4e503b',
              fontSize: '13px',
              borderRadius: 5,
            }}
            buttonText="Got it!"
            location="bottom"
            style={{ background: '#222', zIndex: 9000001, padding: 10 }}
          >
            This website uses cookies to ensure you get the best experience on
            our website{' '}
            <Link
              className="ml-2"
              to={`${process.env.NEXT_PUBLIC_WEBSITE_URL}/cookies-policy`}
            >
              More Info
            </Link>
          </CookieConsent>
        </GlobalStateProvider>
      </NotificationProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
