import axios from 'axios';

const UNAUTHORIZED = 401;

export const axiosBackend = axios.create({
  baseURL: `${process.env.UPT_API_URL}/dashboard`,
});

export const axiosFrontend = axios.create();
axiosFrontend.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === UNAUTHORIZED) {
      window.location.href = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/#/auth/login`;
    }
    return Promise.reject(error);
  },
);
