import React, { ChangeEventHandler } from 'react';

import { FormErrors } from '../FormErrors';

export interface ISwitchProps {
  checked: boolean | undefined;
  error?:
    | string
    | [
        {
          message: string;
          code: string;
        },
      ]
    | undefined;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Switch: React.FC<ISwitchProps> = ({
  checked,
  error,
  disabled = false,
  onChange,
}) => {
  return (
    <div>
      <div>
        <label className="relative inline-flex items-center mb-1 cursor-pointer">
          <input
            checked={checked}
            className="sr-only peer"
            disabled={disabled}
            type="checkbox"
            onChange={onChange}
          />
          <div className="w-14 h-7 bg-gray-200 border border-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-white peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[8px] after:bg-gray-300 after:shadow-sm after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#00B49C] peer-disabled:cursor-not-allowed"></div>
        </label>
      </div>
      <FormErrors error={error} />
    </div>
  );
};

export default Switch;
