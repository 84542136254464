import React, { useState } from 'react';

import simpleSvgPlaceholder from '@cloudfour/simple-svg-placeholder';

import { CurrencyOwnerType } from '@/types/CurrencyOwnerTypes';

export interface IUserImageProps {
  user: CurrencyOwnerType;
}

export const UserImage: React.FC<IUserImageProps> = ({ user }) => {
  const getRandomColor = (str: string, s: number, l: number) => {
    let hash = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return `hsl(${h}, ${s}%, ${l}%)`;
  };
  const [src, setSrc] = useState(
    user.profile_image ??
      simpleSvgPlaceholder({
        width: 44,
        height: 44,
        text: user.first_name.charAt(0).toUpperCase(),
        bgColor: getRandomColor(user.api_identifier, 50, 50),
        textColor: '#ffffff',
        fontSize: 18,
        fontWeight: 'light',
      }),
  );

  return (
    <div className="px-3">
      <div className="relative flex items-center w-12 h-12 overflow-hidden">
        <img
          alt={user.first_name}
          className="rounded-full"
          src={src}
          onError={() =>
            setSrc(
              simpleSvgPlaceholder({
                width: 44,
                height: 44,
                text: user.first_name.charAt(0).toUpperCase(),
                bgColor: getRandomColor(user.api_identifier, 50, 50),
                textColor: '#ffffff',
                fontSize: 18,
                fontWeight: 'light',
              }),
            )
          }
        />
      </div>
    </div>
  );
};

export default UserImage;
