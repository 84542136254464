import React from 'react';

import { FormErrors } from '../FormErrors';

export interface IUploadImageProps {
  alt: string;
  error?:
    | string
    | [
        {
          message: string;
          code: string;
        },
      ]
    | undefined;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value: string;
}

export const UploadImage: React.FC<IUploadImageProps> = ({
  alt,
  error,
  label,
  value = null,
  onChange,
}) => {
  return (
    <>
      <div className="flex items-center">
        <div className="mr-6">
          {value ? (
            <img alt={alt} className="preview" src={value} />
          ) : (
            <div className="border-2 border-dashed rounded-full w-14 h-14 border-blue-850" />
          )}
        </div>
        <label className="flex items-center">
          {value ? (
            <p className="mr-4 text-green-500 underline bg-transparent border-0 cursor-pointer hover:no-underline">
              Remove
            </p>
          ) : (
            <p className="mr-4 text-green-500 underline bg-transparent border-0 cursor-pointer hover:no-underline">
              {label}
            </p>
          )}
          <input
            accept="image/*"
            className="invisible"
            title={label}
            type="file"
            onChange={onChange}
          />
        </label>
      </div>
      <FormErrors error={error} />
    </>
  );
};

export default UploadImage;
