import { ItemType } from '@/components/atoms';
import {
  PartnerPaymentTypeType,
  PartnerStatusesType,
  PartnerType,
} from '@/types/PartnerTypes';
import { RechargeCodeStatusesType } from '@/types/RechargeCodeTypes';
import { StoreStatusesType } from '@/types/StoreTypes';
import { TransactionStatusesType } from '@/types/TransactionTypes';
import { UserRolesType, UserStatusesType, UserType } from '@/types/UserTypes';

// Options
export const partnersStatusesOptions: {
  label: string;
  value: PartnerStatusesType;
}[] = [
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Blocked', value: 'blocked' },
];

export const partnerPaymentTypeOptions: {
  label: string;
  value: PartnerPaymentTypeType;
}[] = [
  { label: 'Payment In Advance', value: 'in_advance' },
  { label: 'On Credit', value: 'on_credit' },
];

export const partnerBillingPeriodOptions = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Twice Monthly', value: 'twice_monthly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
];

export const partnerYearlyGrossRevenueOptions = [
  { label: 'Less Than 1M', value: 'less_than_1M' },
  { label: '1M - 10M', value: '1m_10m' },
  { label: '10M - 50M', value: '10m_50m' },
  { label: '50M - 200M', value: '50m_200m' },
  { label: '200M - 1000M', value: '200m_1000m' },
  { label: 'Greater Than 1000M', value: 'greater_than_1000m' },
];

export const partnerSectorsOptions = [
  {
    label: 'Banking Insurance Financial Services',
    value: 'banking_insurance_financial_services',
  },
  { label: 'Consulting', value: 'consulting' },
  { label: 'Consumer Services', value: 'consumer_services' },
  { label: 'Data Analytics', value: 'data_analytics' },
  { label: 'Digital Marketing', value: 'digital_marketing' },
  { label: 'Education', value: 'education' },
  { label: 'Energy Utilities', value: 'energy_utilities' },
  { label: 'FMCG', value: 'fmcg' },
  { label: 'Hospitality', value: 'hospitality' },
  { label: 'Leisure Entertainment', value: 'leisure_entertainment' },
  { label: 'Life Sciences', value: 'life_sciences' },
  { label: 'Loyalty Management', value: 'loyalty_management' },
  { label: 'Other', value: 'other' },
  { label: 'Other Manufacturing', value: 'other_manufacturing' },
  { label: 'Payment Solutions', value: 'payment_solutions' },
  { label: 'Retail', value: 'retail' },
  { label: 'Software And Technology', value: 'software_and_technology' },
  { label: 'Telecommunications', value: 'telecommunications' },
  { label: 'Travel Transport', value: 'travel_transport' },
];

export const storeStatusesOptions: {
  label: string;
  value: StoreStatusesType;
}[] = [
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Blocked', value: 'blocked' },
];

export const userStatusesOptions: { label: string; value: UserStatusesType }[] =
  [
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
    { label: 'Blocked', value: 'blocked' },
  ];

export const userRolesOptions: { label: string; value: UserRolesType }[] = [
  { label: 'Store Admin', value: 'store_admin' },
  { label: 'Store Manager', value: 'store_manager' },
  { label: 'Store User', value: 'store_user' },
  { label: 'Partner User', value: 'partner_user' },
];

export const reportsTypesOptions: { label: string; value: string }[] = [
  { label: 'Issuances', value: 'issuances' },
  { label: 'Member Registrations', value: 'member-registrations' },
  { label: 'Redemptions', value: 'redemptions' },
  { label: 'Reversals', value: 'reversals' },
  { label: 'Vouchers', value: 'vouchers' },
];

export const rechargeCodeStatusesOptions: {
  label: string;
  value: RechargeCodeStatusesType;
}[] = [
  { label: 'Used', value: 'used' },
  { label: 'Pending', value: 'pending' },
];

export const transactionsStatusesOptions: {
  label: string;
  value: TransactionStatusesType;
}[] = [
  { label: 'Adjusted', value: 'adjusted' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Completed', value: 'completed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Pending External Approval', value: 'pending_external_approval' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Reversed', value: 'reversed' },
];

export const transactionsTypesOptions = [
  { label: 'Issued', value: 'accrual' },
  { label: 'Redeemed', value: 'redemption__standard' },
  { label: 'Reversed', value: 'reversal' },
];

export const partnerInAdvanceStoreBalanceSourcesOptions = [
  { label: 'Partner', value: 'partner' },
  { label: 'Store', value: 'store' },
];

// Mappings
export const userSessionEventsReasonsMapping: { [key: string]: string } = {
  wrong_password: 'Wrong Password',
};

export const userSessionEventsEventsMapping: { [key: string]: string } = {
  login: 'Login',
  logout: 'Logout',
  forced_logout: 'Forced Logout',
};

export const partnerAuditLogFieldsMapping: { [key: string]: string } = {
  billing_period: 'Billing Period',
  company_name: 'Company Name',
  default_price: 'Default price',
  e_voucher_redemption_enabled: 'Voucher Redemption',
  fiat_currency: "Partner's Default Fiat Currency",
  issuance_enabled: 'Issuance',
  issuance_fixed_rate_point_amount: 'Default Issuance Fixed Amount',
  issuance_fixed_rate_point_fiat_amount: 'Default Issuance Fixed Fiat Amount',
  issuance_percentage: 'Default Issuance Percentage',
  issuance_rate_type: 'Default Issuance Rate',
  max_issuance_fiat_amount: 'Maximum issuance amount',
  max_redemption_fiat_amount: 'Maximum redemption amount',
  member_registration_enabled: 'Register member',
  min_issuance_fiat_amount: 'Minimum issuance amount',
  min_redemption_fiat_amount: 'Minimum redemption amount',
  on_credit_credit_fiat_amount: 'Amount of credit',
  payment_type: 'Payment Term',
  redemption_enabled: 'Instore Redemption',
  redemption_fixed_rate_point_amount: 'Default Issuance Fixed Amount',
  redemption_fixed_rate_point_fiat_amount: 'Default Issuance Fixed Fiat Amount',
  redemption_multiplier: 'Default Multiplier',
  redemption_rate: 'Default Redemption Rate',
  redemption_rate_type: 'Default Issuance Rate Type',
  reversal_enabled: 'Reversal',
  sponsor_code: 'Sponsor / Code',
  status: 'Status',
  web_app_slug: 'Web App Link',
  web_app_session_duration: 'Session Timeout Limit',
};

export const storeAuditLogFieldsMapping: { [key: string]: string } = {
  address_building: 'Building',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  address_line_3: 'Address Line 3',
  api_identifier: 'ID',
  city: 'City',
  contact_person_name: 'Contact',
  country: 'Country',
  e_voucher_redemption_enabled: 'Voucher Redemption',
  issuance_enabled: 'Issuance',
  issuance_fixed_rate_point_amount: 'Default Issuance Fixed Amount',
  issuance_fixed_rate_point_fiat_amount: 'Default Issuance Fixed Fiat Amount',
  issuance_percentage: 'Default Issuance Percentage',
  issuance_rate_type: 'Default Issuance Rate',
  max_issuance_fiat_amount: 'Maximum issuance amount',
  max_redemption_fiat_amount: 'Maximum redemption amount',
  member_registration_enabled: 'Register member',
  min_issuance_fiat_amount: 'Minimum issuance amount',
  min_redemption_fiat_amount: 'Minimum redemption amount',
  name: 'Name',
  partner: 'Partner',
  phone_number: 'Phone Number',
  postal_code: 'Postal Code',
  redemption_enabled: 'Instore Redemption',
  redemption_fixed_rate_point_amount: 'Default Issuance Fixed Amount',
  redemption_fixed_rate_point_fiat_amount: 'Default Issuance Fixed Fiat Amount',
  redemption_multiplier: 'Redemption Multiplier',
  redemption_rate: 'Redemption Rate',
  redemption_rate_type: 'Default Issuance Rate Type',
  reversal_enabled: 'Reversal',
  state_province: 'State / Province',
  status: 'Status',
  store_type: 'Store Type',
  terminal_id: 'Terminal ID',
};

export const userAuditLogFieldsMapping: { [key: string]: string } = {
  api_identifier: 'ID',
  email: 'E-mail',
  first_name: 'First Name',
  last_name: 'Last Name',
  max_issuance_fiat_amount: 'Maximum amount to issue',
  max_redemption_fiat_amount: 'Maximum amount to redem',
  partner: 'Partner',
  password: 'Password',
  phone_number: 'Phone Number',
  phone_prefix: 'Phone Prefix',
  pin: 'PIN',
  role: 'Role',
  status: 'Status',
  store: 'Store',
  username: 'Username',
};

// Tab Navigations
export const partnersManagementPartnersTabNavigation = (
  id: string | string[] | undefined,
  partner: PartnerType,
): ItemType[] => {
  return [
    {
      href: `/partners-management/partners/${id}/overview`,
      title: 'Partner Overview',
    },
    {
      href: `/partners-management/partners/${id}/stores`,
      title: 'Partner Stores',
    },
    {
      href: `/partners-management/partners/${id}/users`,
      title: 'Partner Users',
    },
    {
      href: `/partners-management/partners/${id}/transactions`,
      title: 'Partner Transactions',
    },
    {
      href: `/partners-management/partners/${id}/balance-history`,
      title: 'Partner Balance History',
      showIf: partner.issuance_enabled && Boolean(partner.payment_type),
    },
    {
      href: `/partners-management/partners/${id}/logs`,
      title: 'Partner Logs',
    },
  ];
};

export const partnersManagementStoresTabNavigation = (
  id: string | string[] | undefined,
): ItemType[] => {
  return [
    {
      href: `/partners-management/stores/${id}/overview`,
      title: 'Store Overview',
    },
    {
      href: `/partners-management/stores/${id}/transactions`,
      title: 'Store Transactions',
    },
    {
      href: `/partners-management/stores/${id}/users`,
      title: 'Store Users',
    },
    {
      href: `/partners-management/stores/${id}/logs`,
      title: 'Store Logs',
    },
  ];
};

export const partnersManagementUsersTabNavigation = (
  id: string | string[] | undefined,
  user: UserType,
): ItemType[] => {
  return [
    {
      href: `/partners-management/users/${id}/overview`,
      title: 'User Overview',
    },
    {
      href: `/partners-management/users/${id}/transactions`,
      title: 'User Transactions',
      showIf: user.role !== 'partner_user',
    },
    {
      href: `/partners-management/users/${id}/logs/sign-in-activity`,
      title: 'User Logs',
    },
  ];
};
